import { Injectable } from '@angular/core';
import { IAccountDetailsDTO } from '../../../features/account/model/account.model';
import { AccountApiService } from '../../../features/account/services/api/account-api.service';
import { BehaviorSubject, Observable, filter, map, of, skip, take } from 'rxjs';
import { RefreshTokenService } from './refresh-token/refresh-token.service';
import { TranslateService } from '@ngx-translate/core';
import { EUserRole } from '../../model/user.model';

@Injectable({
  providedIn: 'root',
})
export class AccountDataService {
  private _accountData$ = new BehaviorSubject<IAccountDetailsDTO | null>(null);
  accountData$ = this._accountData$.asObservable();

  isLoggedIn$ = this.accountData$.pipe(map((data) => !!data));
  userRoles$ = this.accountData$.pipe(
    map((data) => data?.authorities),
    filter(Boolean)
  );

  constructor(
    private accountApiService: AccountApiService,
    private refreshTokenService: RefreshTokenService,
    private translate: TranslateService
  ) {}

  get accountData() {
    return this._accountData$.value;
  }

  isInstaller = () =>
    !!this.accountData?.authorities.includes(EUserRole.ROLE_INSTALLER);

  isAdmin = () =>
    !!this.accountData?.authorities.includes(EUserRole.ROLE_ADMIN);

  isInstallerOnly = () =>
    this.accountData?.authorities.length === 1 &&
    this.accountData.authorities[0] === EUserRole.ROLE_INSTALLER;

  checkIfUserIsAuthorized$(): Observable<boolean> {
    if (this.refreshTokenService.hasToken && !this._accountData$.value) {
      this.refreshData();
      return this.isLoggedIn$.pipe(skip(1));
    }
    return this.isLoggedIn$;
  }

  refreshData() {
    if (this.refreshTokenService.hasToken) {
      return this.accountApiService
        .getAccount()
        .pipe(take(1))
        .subscribe((accountData) => this.setData(accountData));
    }
    return of(undefined);
  }

  removeData = () => this._accountData$.next(null);

  private setData = (accountData: IAccountDetailsDTO) => {
    this.translate.setDefaultLang(accountData.langKey.toLocaleLowerCase());
    return this._accountData$.next(accountData);
  };
}
